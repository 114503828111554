const initBlock = async function( block ) {
	const service = block.find( "[data-service]" );
	const serviceBody = service.find( "[data-service-body]" );
	const serviceImage = block.find( "[data-service-image]" );

	serviceBody.not( ":first" ).hide();
	service.eq( 0 ).addClass( "active" );
	serviceImage.eq( 0 ).addClass( "active" );

	service.find( ".section-services-we-provide__item-header" ).on( "click", function() {
		const index = $( this ).parent().index();
		const currentService = service.eq( index );
		const currentServiceBody = serviceBody.eq( index );
		const currentServiceImage = serviceImage.eq( index );

		if ( currentService.hasClass( "active" ) ) {
			currentService.removeClass( "active" );
			currentServiceImage.removeClass( "active" );
			currentServiceBody.slideUp( 300 );
			serviceImage.eq( 0 ).addClass( "active" );
		} else {
			service.not( currentService ).removeClass( "active" );
			serviceImage.not( currentServiceImage ).removeClass( "active" );
			serviceBody.not( currentServiceBody ).slideUp( 300 );
			currentService.addClass( "active" );
			currentServiceImage.addClass( "active" );
			currentServiceBody.slideDown( 300 );
		}
	} );
};

themeUtils.loadBlock( initBlock, "services-we-provide", ".section-services-we-provide" );
